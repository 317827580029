import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Cloud10 from 'common/assets/image/app-minimal/v2/checkout-clouds1.png';

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'DM Sans', sans-serif;
        font-size: 16px;
        scroll-behavior: smooth;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'DM Sans', sans-serif;
        /* font-family: 'Work Sans', sans-serif; */
    }

    @media (max-width: 700px) {
        .logosA{
            display: none;
        }
    }
    .sticky-outer-wrapper{
        height: 100px;
    }
    p:last-of-type {
        margin-bottom: 0;
    }

    section {
        position: relative;
    }

    .reusecore__button {
        font-weight: 500;
    }

    .container {
        max-width: 1360px;
        @media only screen and (max-width: 1600px) {
            max-width: 1170px;
            padding-left: 0;
            padding-right: 0;
        }
        @media (max-width: 1199px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media only screen and (max-width: 667px) {
            width: 100%;
        }
        @media only screen and (max-width: 667px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @media only screen and (width: 375px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .drawer-content-wrapper {
        @media (max-width: 767px) {
            width: 300px !important;
        }

        .drawer-content {
            padding: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: 767px) {
                padding: 50px 40px 30px 40px;
            }

            .mobile_menu {
                margin-bottom: 40px;
                flex-grow: 1;
                @media (max-width: 767px) {
                    margin-bottom: 30px;
                }

                li {
                    margin-bottom: 35px;
                    @media (max-width: 767px) {
                        margin-bottom: 25px;
                    }

                    a {
                        font-size: 20px;
                        font-weight: 400;
                        color: #343d48;
                        position: relative;
                        transition: 0.15s ease-in-out;
                        @media (max-width: 767px) {
                            font-size: 18px;
                        }

                        &:hover {
                            color: #108AFF;
                        }

                        &:before {
                            content: '';
                            width: 7px;
                            height: 7px;
                            background: #108AFF;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: -15px;
                            transform: translateY(-50%);
                            opacity: 0;
                        }
                    }

                    &.is-current {
                        a {
                            color: #108AFF;

                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .navbar_drawer_button button {
                width: 100%;
            }
        }

        .reusecore-drawer__close {
            width: 34px;
            height: 34px;
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @media (max-width: 767px) {
                top: 15px;
                right: 15px;
            }

            &:before {
                content: 'X';
                font-family: 'DM Sans', sans-serif;
                font-size: 26px;
                color: #108AFF;
                display: block;
            }
        }
    }

    #faq_section h3, #faq_section p {
        text-align: left;
    }
    .noHarp #FAQ{
        display: none;
    }
    #checkout #FAQ{
        display: none;
    }
    .sticky-nav-active {
        .sassminimal_navbar {
            background: #fff;
            box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
            padding: 15px 0;

            .main-logo {
                display: none;
            }

            .stricky-logo {
                display: block;
            }

            .main_menu li a {
                color: #0F2137 !important;
            }

            .main_menu li:hover a,
            .main_menu li.is-current a {
                color: #320b82;
            }

            .reusecore-drawer__handler {
                .hamburgMenu__bar {
                    > span {
                        background-color: #0F2137;
                    }
                }
            }

            .navbar_button button {
                color: #320b82;
                border-color: #0F2137;

                &:hover {
                    color: #1089FF;
                    border-color: #1089FF;
                }
            }

            .navbar_button_two button {
                color: #fff;
                background-color: #0F2137;

                &:hover {
                    color: #fff;
                    background-color: #1089FF;
                }
            }
        }
    }

    .sassminimal_navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: 0.35s ease-in-out;
        padding: 30px 0;

        .main-logo {
            display: block;
        }

        .stricky-logo {
            display: none;
        }

        .mainMenuWrapper {
            flex: 1 1 100%;
            right: 0;
            float: right;
            display: block !important;

            @media (max-width: 991px) {
                flex: 0 0 auto;
                margin-left: auto;
            }

        }

        .main_menu {
            margin-left: 45px;
            float: right;

            li {
                display: inline-block;
                padding-left: 13px;
                padding-right: 13px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.is-current {
                    a {
                        color: #2678dd;
                        text-shadow: 0 1px 0px #ffffff;
                    }
                }

                a {
                    padding: 5px;
                    transition: 0.15s ease-in-out;
                    font-style: normal;
                    font-size: 15px;
                    color: #000;
                    font-weight: bold;

                    &:hover {
                        color: #5676ff;
                        text-shadow: 0 1px 0px #ffffff;
                    }
                }
            }

            @media (max-width: 990px) {
                display: none;
            }
        }

        .navbar_button {
            margin-left: auto;

            button {
                background-color: transparent;
                color: #000;
                font-size: 15px;
                font-weight: bold;
                padding: 0;
                border-radius: 0;
                padding-bottom: 6px;
                border-bottom: 1px solid rgba(255, 255, 255, .6);
                transition: all 500ms ease;

                &:hover {
                    color: #0F2137;
                    border-bottom-color: #0F2137;
                }
            }

            @media (max-width: 990px) {
                display: none;
            }
        }

        .navbar_button_two {
            margin-left: 30px;

            button {
                background-color: #fff;
                color: #320b82;
                font-size: 15px;
                font-weight: bold;
                border-radius: 22.5px;
                transiton: all 500ms ease;

                &:hover {
                    background-color: #0F2137;
                    color: #fff;
                }
            }

            @media (max-width: 990px) {
                display: none;
            }
        }

        .reusecore-drawer__handler {
            @media (min-width: 991px) {
                display: none !important;
            }

            .hamburgMenu__bar {
                > span {
                    background-color: #5676ff;
                }
            }
        }
    }
    .tContainer{
        max-width: 960px;
    }
    .pp{
        padding: 30px 15px 30px 15px !important;
    }
    .tbox{
        width: 100%;
        padding: 15px;
        border-radius: 7px;
        -webkit-box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.08);
        box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.08);
        float: left;
        background: white;
        border: 1px solid #dddddda6;
        margin-bottom: 20px;
    }

    .tbox h3{
        margin: 0;
        font-size: 16px;
    }
    .transition {
        background: linear-gradient(180deg, #FFFFFF 18.4%, #E9F1FC 100%);
        width: 100%;
        height: 80px;

    }
    .transition-reverse{
        background: linear-gradient(180deg, #E9F1FC 18.4%, #FFFFFF 61.42%);
        width: 100%;
        height: 80px;

    }
    #testimonial_section{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        margin-top: 50px;
    }
    .sticky-nav-checkout{
        background: linear-gradient(180deg, #CFDFF6 -8.7%, #FFFFFF 100%);
        min-height: 130px;

    }
    .checkout-clouds {
        background-image: url(${Cloud10});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top left;
        position: relative;
        width: 100%;
        height: 130px;
        opacity: 1;
    }
    .sticky-nav-active .checkout-clouds{
        display: none;
    }
    .sticky-inner-wrapper{
        z-index: 999999999999!important;
    }

    .flight-section{
        margin-top: -33px;
        position: relative;
        z-index: 999999;
    }

    @media (max-width: 575px) {
        #testimonial_section {
            margin-top: 50px;
        }
    }
    .display-none{
        display: none;
    }

    .breadcrumb {
        display: flex;
        max-width: 65%;
    }
    .breadcrumb-item {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-top: 15px;
    }
    .breadcrumb-item:not(:last-child)::after {
        content: '';
        display: block;
        width: 27%;
        height: 1px;
        background-color: #E2E8F0;
        margin-right: 20px;
    }
    .breadcrumb-item span {
        background: white;
        border: 1px solid #E2E8F0;
        border-radius: 50px;
        color: black;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        text-align: center;
        padding-top: 4px;
        font-size: 12px;
    }
    .breadcrumb-item span.active {
        background: #2678DD;
        color: white;

    }
    .breadcrumb-item h5 {
        padding: 0;
        margin: 0;
        font-weight: 500;
        padding-right: 20px;
        
    }
    @media (max-width: 1200px) {
        .breadcrumb {
            display: flex;
            max-width: 100%;
        }
    }
    @media (max-width: 700px) {
        .breadcrumb {
            display: none;
            max-width: 100%;
        }
    }
    @media (max-width: 900px) {
        .boxin p {
            font-size: 14px !important;
        }
        .leftColumn  {
            width: 100% !important;
            flex: 1 1 100% !important;
        }
        .rightColumn  {
            display: none;
            width: 100% !important;
            flex: 1 1 100% !important;
            margin-right: 0!important;
            margin-left: 0!important;
            transform: none !important;
            margin-top: 30px !important;
        }
    }
    .drawer{
        z-index: 2147483647;
    }
    @media (max-width: 800px) {
        .reasonsTitle{
            width: 100%;
            max-width: 100%!important;
        }
        #faq_section{
            width: 100% !important;
        }
        .faws{
            padding-top: 0px;
        }
    }
`;

export const AppWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .sticky-active {
    .navbar {
      padding: 20px 0 21px;
      background-color: ${themeGet('colors.white', '#ffffff')};
      @media only screen and (max-width: 1366px) {
        padding: 15px 0 16px;
      }
      .mobile-menu {
        top: 72px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
`;

export const SectionHeader = styled.header`
  max-width: 550px;
  width: 100%;
  margin: 0 auto 45px;
  text-align: center;
  @media only screen and (max-width: 1600px) {
    margin-bottom: 40px;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 2;
    margin-bottom: 12px;
    color: ${themeGet('colors.textColor', '#343D48')};
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 28px;
    line-height: 1.3;
    font-weight: 500;
    color: ${themeGet('colors.headingColor', '#0F2137')};
    margin-bottom: 18px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: 1600px) {
      font-size: 24px;
      letter-spacing: -0.7px;
      margin-bottom: 12px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 38px;
      letter-spacing: -0.5px;
    }
  }
  &.section-header-two {
    text-align: left;
    margin-top: -10px;
    margin-bottom: 40px;
    h2 {
      font-size: 40px;
      line-height: 1.35;
      margin-bottom: 25px;
      letter-spacing: -0.5px;
      @media (max-width: 1600px) {
        font-size: 32px;
      }
      @media (max-width: 768px) {
        font-size: 30px;
      }
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
  }
`;

export const GradientWrapper = styled.section`
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    180deg,
    rgba(246, 247, 249, 0) 0%,
    #f3f7fb 36.35%
  );
`;
export default GlobalStyle;
